//@author: devin
import { DataAccess } from "../dataaccess/data.access";
import {
    FormI,
    FormItemI,
    FormItemMigrateI,
    FormItemOption,
    FormPageI,
    FormSearchI,
    FormSectionI,
} from "../pojo/FormI";
import { S25Util } from "../util/s25-util";
import { jSith } from "../util/jquery-replacement";
import { Cache, Invalidate } from "../decorators/cache.decorator";

export class FormService {
    public static getFormInstance(formInstanceId: number) {
        if (!formInstanceId) {
            return FormService.getNewFormInstance();
        } else {
            return DataAccess.get("/forms/instance.json?formInstanceId=" + formInstanceId);
        }
    }

    public static getNewFormInstance() {
        return DataAccess.post("/forms/instance.json");
    }

    /**
     *
     * @param onlyActive
     * @return { forms : [ { formId, name, display, active }, ... ] }
     */
    @Cache({ targetName: "FormService", immutable: true })
    public static getForms(onlyActive?: boolean) {
        return DataAccess.get("/forms/forms.json?active=" + (onlyActive ? "T" : "F")).then((resp) => {
            return (resp && resp.forms) || [];
        });
    }

    public static getForm(formId: number) {
        return DataAccess.get("/forms/form.json?formId=" + formId);
    }

    public static flattenItems(item: FormItemI) {
        let flatItems: FormItemI[] = [];
        flatItems.push(item);
        jSith.forEach(item.children, (c: FormItemI) => {
            flatItems = flatItems.concat(FormService.flattenItems(c));
        });
        return flatItems;
    }

    public static getRootsTrees(allItems: FormItemI[]) {
        let roots: FormItemI[] = [];
        let stack: FormItemI[] = [];
        jSith.forEach(allItems, (_: any, item: FormItemI) => {
            if (!item.parentItemId) {
                roots.push(item);
                stack.push(item);
            }
        });

        while (stack.length) {
            let currentItem = stack.pop();
            jSith.forEach(allItems, (_: any, item: FormItemI) => {
                if (!item.visited && item.itemPath === currentItem.itemPath + "." + item.itemId) {
                    item.visited = true;
                    stack.push(item);
                    currentItem.children.push(item);
                }
            });
            currentItem.children && currentItem.children.sort(S25Util.shallowSort("sortOrder", true));
        }

        roots.sort(S25Util.shallowSort("sortOrder", true));
        return roots;
    }

    public static getFormModelById(formId: number): Promise<FormI> {
        return FormService.getForm(formId).then((form) => {
            return FormService.getFormModel(form);
        });
    }

    public static getFormModel(form: any): FormI {
        let formModel: FormI = {
            pages: [],
            formInstanceId: form.formInstanceId,
        };

        formModel.formId = S25Util.parseInt(form.form_id);
        formModel.name = form.name;
        formModel.display = form.display;
        formModel.descr = form.descr;
        formModel.purpose = form.purpose;
        formModel.active = S25Util.toBool(form.active);

        jSith.forEach(form.pages, (_: any, page: any) => {
            let pageModel: FormPageI = {};
            pageModel.formPageId = S25Util.parseInt(page.form_page_id);
            pageModel.sortOrder = S25Util.parseFloat(page.sort_order);
            pageModel.display = page.display;
            pageModel.descr = page.descr;
            pageModel.active = S25Util.toBool(page.active);
            pageModel.sections = [];

            jSith.forEach(form.sections, (_: any, section: any) => {
                let formPageId: number = S25Util.parseInt(section.form_page_id);
                if (formPageId === pageModel.formPageId) {
                    let sectionModel: FormSectionI = {};
                    sectionModel.formPageSectionId = S25Util.parseInt(section.form_page_section_id);
                    sectionModel.sortOrder = S25Util.parseFloat(section.sort_order);
                    sectionModel.display = section.display;
                    sectionModel.descr = section.descr;
                    sectionModel.active = S25Util.toBool(section.active);

                    let sectionItems: FormItemI[] = [];
                    jSith.forEach(form.items, (_: any, item: any) => {
                        let formPageSectionId: number = S25Util.parseInt(item.form_page_section_id);
                        if (formPageSectionId === sectionModel.formPageSectionId) {
                            let itemModel: FormItemI = {};
                            itemModel.formPageSectionItemId = S25Util.parseInt(item.form_page_section_item_id);
                            itemModel.name = item.name;
                            itemModel.sortOrder = S25Util.parseFloat(item.sort_order);
                            itemModel.display = item.display;
                            itemModel.descr = item.descr;
                            itemModel.active = S25Util.toBool(item.active);
                            itemModel.required = S25Util.toBool(item.required);
                            itemModel.multivalued = S25Util.toBool(item.multivalued); //todo: HAS DAO -- but can change???
                            itemModel.itemId = S25Util.parseInt(item.item_id);
                            itemModel.parentItemId = item.parent_item_id && S25Util.parseInt(item.parent_item_id);
                            itemModel.itemPath = item.item_path;
                            itemModel.uxType = item.ux_type;
                            itemModel.type = item.type;
                            itemModel.singleSelect = S25Util.toBool(item.single_select); //todo: HAS DAO -- can change???

                            if (item.options) {
                                itemModel.optionHash = {};
                                itemModel.options = [];
                                jSith.forEachObj(item.options, (opt: any) => {
                                    let option: FormItemOption = {
                                        itemId: S25Util.toStr(opt.option_uuid),
                                        itemName: S25Util.toStr(opt.option_display),
                                        active: S25Util.toBool(opt.active),
                                    };
                                    itemModel.options.push(option);
                                    itemModel.optionHash[option.itemId] = option;
                                });
                            }

                            sectionItems.push(itemModel);
                        }
                    });

                    sectionModel.items = FormService.getRootsTrees(sectionItems);
                    pageModel.sections.push(sectionModel);
                }
            });

            pageModel.sections.sort(S25Util.shallowSort("sortOrder", true));
            formModel.pages.push(pageModel);
        });

        formModel.pages.sort(S25Util.shallowSort("sortOrder", true));
        return formModel;
    }

    public static getHydratedFormById(formInstanceId: number) {
        return FormService.getFormInstance(formInstanceId).then((formInstance: any) => {
            return FormService.getForm(formInstance.form_id).then((form: any) => {
                return FormService.getHydratedForm(formInstance, form);
            });
        });
    }

    public static onEach(
        item: FormItemI,
        parentItem: FormItemI,
        func: (item: FormItemI, parentItem: FormItemI) => void,
    ) {
        func(item, parentItem);
        jSith.forEachObj([].concat(item.children || []), (c: FormItemI) => {
            FormService.onEach(c, item, func);
        });
    }

    public static matches(formInstanceItems: any[], regex: RegExp) {
        let resp: Boolean = false;
        jSith.forEachObj(
            formInstanceItems,
            (item: any) => {
                if (item.item_path.match(regex)) {
                    resp = true;
                    return resp;
                }
            },
            false,
            true,
        );
        return resp;
    }

    public static enumerateFormInstanceItems(
        idx: number,
        itemsArr: FormItemI[],
        formItem: FormItemI,
        formInstanceItems: any[],
    ) {
        let origIdx = idx;
        let r = new RegExp("^" + S25Util.escapeRegExp(formItem.itemPath + ".{d+}"));
        let multivalued = formItem.multivalued || FormService.matches(formInstanceItems, r);
        if (multivalued) {
            let counter = 0;
            r = new RegExp("^" + S25Util.escapeRegExp(formItem.itemPath + ".{" + counter + "}"));
            while (FormService.matches(formInstanceItems, r)) {
                let itemCopy: FormItemI = S25Util.deepCopy(formItem);
                let path = itemCopy.itemPath;
                let pathReplace = new RegExp("^" + S25Util.escapeRegExp(path));
                FormService.onEach(itemCopy, null, (ithItem) => {
                    ithItem.itemPath = ithItem.itemPath.replace(pathReplace, path + ".{" + counter + "}");
                });
                if (counter === 0) {
                    itemsArr.splice(idx, 1, itemCopy);
                } else {
                    itemsArr.splice(idx, 0, itemCopy);
                }
                idx++;
                counter++;
                r = new RegExp("^" + S25Util.escapeRegExp(formItem.itemPath + ".{" + counter + "}"));
            }

            formItem = itemsArr[origIdx];

            if (formItem.multivalued && !formItem.itemPath.match(/{\d+}$/)) {
                let path = formItem.itemPath;
                let pathReplace = new RegExp("^" + S25Util.escapeRegExp(path));
                FormService.onEach(formItem, null, (ithItem) => {
                    ithItem.itemPath = ithItem.itemPath.replace(pathReplace, path + ".{0}");
                });
            }

            //indicate last so we know where to place the "add another" button
            let lastIdx = idx === 0 ? 0 : idx - 1;
            itemsArr[lastIdx].last = true;

            jSith.forEach(
                formItem.children,
                (i: number, item: FormItemI) => {
                    FormService.enumerateFormInstanceItems(i, formItem.children, item, formInstanceItems);
                },
                false,
                false,
                true,
            );
        }
    }

    public static getHydratedForm(formInstance: any, form: any) {
        let now = Date.now();

        form.formInstanceId = S25Util.parseInt(formInstance.form_instance_id);
        let formModel: FormI = FormService.getFormModel(form);

        jSith.forEachObj(formModel.pages, (page: FormPageI) => {
            jSith.forEachObj(page.sections, (section: FormSectionI) => {
                jSith.forEach(
                    section.items,
                    (i: number, item: FormItemI) => {
                        FormService.enumerateFormInstanceItems(i, section.items, item, formInstance.items);
                        FormService.onEach(item, null, (ithItem) => {
                            jSith.forEachObj(formInstance.items, (formInstanceItem: any) => {
                                let instPath = formInstanceItem.item_path;
                                let hasMultiSelection = !!instPath.match(/{uuid:.*?}/);
                                if (hasMultiSelection) {
                                    let containerPath = instPath.replace(/\.{uuid:.*?}/g, "");
                                    if (containerPath === ithItem.itemPath) {
                                        let uuid = instPath.match(/{uuid:(.*?)}/)[1];
                                        ithItem.valueArr = ithItem.valueArr || [];
                                        ithItem.valueArr.push({
                                            itemId: uuid,
                                            itemName:
                                                (ithItem.optionHash[uuid] && ithItem.optionHash[uuid].itemName) || uuid,
                                            isSelected: S25Util.toBool(formInstanceItem.value),
                                        });
                                    }
                                } else if (instPath === ithItem.itemPath) {
                                    ithItem.value = formInstanceItem.value;
                                }
                            });
                        });
                    },
                    false,
                    false,
                    true,
                );
            });
        });

        console.log(formModel);
        console.log("Processing Time: " + (Date.now() - now) + "ms");

        return formModel;
    }

    public static updateItemValue(formInstanceId: number, parentItemPath: string, itemPath: string, value: any) {
        let item: any = {};
        item[itemPath] = value;
        let payload: any = {
            formInstanceId: formInstanceId,
            prefix: parentItemPath,
            item: item,
        };
        return DataAccess.put("/forms/instance/item.json", payload);
    }

    public static search(searchModel: FormSearchI) {
        return DataAccess.post("/forms/search/results.json", searchModel);
    }

    public static formItemPayload(item: FormItemI) {
        let itemPayload: any = {
            item_id: item.itemId,
            parent_item_id: item.parentItemId,
            name: item.name,
            display: item.display,
            descr: item.descr,
            purpose: item.purpose,
            active: item.active ? 1 : 0,
            min_val: item.minVal,
            max_val: item.maxVal,
            validation_rule: item.validationRule,
            type: item.type,
            ux_type: item.uxType,
            sort_order: item.sortOrder,
            children_root: {
                status: "mod",
                child: [],
            },
        };

        jSith.forEachObj(item.children, (item: FormItemI) => {
            itemPayload.children_root.child.push(FormService.formItemPayload(item));
        });

        return itemPayload;
    }

    @Invalidate({ serviceName: "FormService", methodName: "getItems" })
    public static createUpdateItem(item: FormItemI) {
        //set sort order
        let sortOrder = 1;
        S25Util.bsf(
            item,
            (node: any, parentNode: any, childKey: any) => {
                node.sortOrder = sortOrder;
                sortOrder++;
            },
            "children",
        );
        return DataAccess.put("/forms/item.json", FormService.formItemPayload(item));
    }

    @Invalidate({ serviceName: "FormService", methodName: "getItems" })
    public static deleteItem(itemId: number) {
        return DataAccess.delete("/forms/item.json?itemId=" + itemId);
    }

    @Invalidate({ serviceName: "FormService", methodName: "getItems" })
    public static migrateItem(itemMigrateModel: FormItemMigrateI) {
        return DataAccess.put("/forms/migrate/item.json", itemMigrateModel);
    }

    /**
     *
     * @param onlyActive
     * @return { items : [ { itemId, name, display, active }, ... ] }
     */
    @Cache({ targetName: "FormService", immutable: true })
    public static getItems(onlyActive?: boolean) {
        return DataAccess.get("/forms/items.json?active=" + (onlyActive ? "T" : "F")).then((resp) => {
            return (resp && resp.items) || [];
        });
    }

    public static unwrapChildItems(item: any) {
        item.children &&
            jSith.forEach(item.children, (i: number, child: any) => {
                item.children[i] = child.item;
                FormService.unwrapChildItems(item.children[i]);
            });
    }

    /**
     *
     * @param itemId
     * @return FormItemI
     */
    public static getItem(itemId: number) {
        return DataAccess.get("/forms/item.json?itemId=" + itemId).then((item: any) => {
            FormService.unwrapChildItems(item);
            return item;
        });
    }

    public static createUpdateForm(form: FormI) {
        let formPayload: any = {
            formId: form.formId,
            name: form.name,
            display: form.display,
            descr: form.descr,
            purpose: form.purpose,
            active: form.active ? 1 : 0,
            pages: [],
            sections: [],
            items: [],
        };

        jSith.forEach(form.pages, (pageI: number, p: FormPageI) => {
            formPayload.pages.push({
                form_page_id: p.formPageId,
                sort_order: pageI + 1,
                display: p.display,
                descr: p.descr,
                active: p.active ? 1 : 0,
            });

            jSith.forEach(p.sections, (sectionI: number, s: FormSectionI) => {
                formPayload.sections.push({
                    form_page_section_id: s.formPageSectionId,
                    form_page_id: s.formPageId,
                    sort_order: sectionI + 1,
                    display: s.display,
                    descr: s.descr,
                    active: s.active ? 1 : 0,
                });

                let sortOrder = 1;
                jSith.forEachObj(s.items, (item: FormItemI) => {
                    S25Util.bsf(
                        item,
                        (node: any, parentNode: any, childKey: any) => {
                            node.sortOrder = sortOrder;
                            sortOrder++;
                        },
                        "children",
                    );
                    let items = FormService.flattenItems(item);
                    jSith.forEachObj(items, (flatItem: FormItemI) => {
                        formPayload.items.push({
                            form_page_section_item_id: flatItem.formPageSectionItemId,
                            form_page_section_id: flatItem.formPageSectionId,
                            item_id: flatItem.itemId,
                            parent_item_id: flatItem.parentItemId,
                            item_path: flatItem.itemPath,
                            sort_order: flatItem.sortOrder,
                            display: flatItem.display,
                            descr: flatItem.descr,
                            min_val: flatItem.minVal,
                            max_val: flatItem.maxVal,
                            validation_rule: flatItem.validationRule,
                            ux_type: flatItem.uxType,
                            active: flatItem.active ? 1 : 0,
                            required: flatItem.required ? 1 : 0,
                            multivalued: flatItem.multivalued ? 1 : 0,
                            single_select: flatItem.singleSelect ? 1 : 0,
                            options: flatItem.options.map((option) => {
                                return {
                                    option_uuid: option.itemId,
                                    option_display: option.itemName,
                                    active: option.active ? 1 : 0,
                                };
                            }),
                        });
                    });
                });
            });
        });

        return DataAccess.put("/forms/form.json", formPayload);
    }

    public static deleteForm(formId: number) {
        return DataAccess.delete("/forms/form.json?formId=" + formId);
    }

    public static getTypes() {
        return [
            {
                name: "string",
                display: "String",
                compatibleUXTypes: [
                    "text",
                    "textarea",
                    "richtext",
                    "radio",
                    "checkbox",
                    "dropdown",
                    "combobox",
                    "multiselect",
                    "multidropdown",
                    "multicombobox",
                ],
                needsMinMax: true,
            },
            {
                name: "large_string",
                display: "Large Text",
                compatibleUXTypes: ["textarea", "richtext", "text"],
                needsMinMax: true,
            },
            {
                name: "number",
                display: "Number",
                compatibleUXTypes: ["integer", "float", "radio", "checkbox"],
                needsMinMax: true,
            },
            { name: "boolean", display: "Boolean", compatibleUXTypes: ["toggle", "checkbox", "radio", "checkbox"] },
            { name: "datetime", display: "Date/Time", compatibleUXTypes: ["datetime", "date", "time"] },
            { name: "complex", display: "Complex", compatibleUXTypes: [] },
        ];
    }

    public static getUXTypes() {
        let allUXTypes = [
            { name: "text", display: "Text Box" },
            { name: "textarea", display: "Text Area" },
            { name: "richtext", display: "Rich Text" },
            { name: "integer", display: "Integer" },
            { name: "float", display: "Float" },
            { name: "radio", display: "Radio" },
            { name: "checkbox", display: "Checkbox" },
            { name: "toggle", display: "Toggle" },
            { name: "date", display: "Date" },
            { name: "time", display: "Time" },
            { name: "datetime", display: "Datetime" },
            { name: "dropdown", display: "Dropdown" },
            { name: "combobox", display: "Combobox" },
            { name: "multiselect", display: "Multiselect" },
            { name: "multidropdown", display: "Multi-Dropdown" },
            { name: "multicombobox", display: "Multi-Combobox" },
        ];
        jSith.forEachObj(allUXTypes, (uxType) => {
            uxType.compatibleTypes = FormService.getTypes()
                .filter((t) => {
                    return t.compatibleUXTypes.indexOf(uxType.name) > -1;
                })
                .map((t) => t.name);
        });
        return allUXTypes;
    }
}
