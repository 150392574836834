import { DataAccess } from "../dataaccess/data.access";
import { Timeout } from "../decorators/timeout.decorator";
import { jSith } from "../util/jquery-replacement";
import { S25Util } from "../util/s25-util";
import { GroupCategoryI } from "../pojo/GroupCategoryI";
import { Cache } from "../decorators/cache.decorator";

export class GroupService {
    @Timeout
    public static getGroups() {
        return DataAccess.get(DataAccess.injectCaller("/secgroups.json", "GroupService.getGroups")).then(
            function (resp) {
                let data = resp && S25Util.prettifyJson(resp, null, { security_group: true });
                return (data && data.security_groups && data.security_groups.security_group) || [];
            },
            function () {
                return [];
            },
        );
    }

    @Timeout
    public static getGroupItems() {
        return GroupService.getGroups().then(
            function (groups) {
                return groups.map(function (group: any) {
                    return {
                        itemId: parseInt(group.security_group_id),
                        itemName: group.security_group_name + "",
                    };
                });
            },
            function () {
                return [];
            },
        );
    }

    @Timeout
    public static getGroup(groupId: number, includes: any) {
        //includes: rights, members
        return DataAccess.get(
            DataAccess.injectCaller(
                "/secgroup.json?security_group_id=" +
                    groupId +
                    (includes && includes.length ? "&scope=extended&include=" + includes.join("+") : ""),
                "GroupService.getGroup",
            ),
        ).then(function (resp) {
            let data =
                resp && S25Util.prettifyJson(resp, null, { security_group_members: true, security_group_rights: true });
            return data && data.security_groups && data.security_groups.security_group;
        });
    }

    @Timeout
    public static getGroupUsernames(groupId: number) {
        return GroupService.getGroup(groupId, ["members"]).then(
            function (group) {
                let usernames: string[] = [];
                group &&
                    jSith.forEach(group[0].security_group_members, function (_, member) {
                        if (member && member.group_member_username) {
                            usernames.push(member.group_member_username + "");
                        }
                    });
                return usernames;
            },
            function () {
                return [];
            },
        );
    }

    @Timeout
    @Cache({ immutable: true, targetName: "GroupService" })
    public static getGroupCategories() {
        return DataAccess.get(DataAccess.injectCaller("/grcat.json", "GroupService.getGroupCategories"))
            .then((data) => {
                return data?.group_categories?.category;
            })
            .catch((e) => console.error(e));
    }

    @Timeout
    public static getGroupCategoriesForFilter() {
        return GroupService.getGroupCategories()
            .then((resp) => {
                return resp
                    ?.map((category: GroupCategoryI) => {
                        return {
                            itemName: category?.category_name,
                            itemId: parseInt(category?.category_id),
                            security_groups: category?.security_group,
                        };
                    })
                    .sort((a: any, b: any) => {
                        const nameA = a.itemName.toLowerCase();
                        const nameB = b.itemName.toLowerCase();

                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;

                        return 0;
                    });
            })
            .catch((error) => console.error(error));
    }
}
