//@author travis
import { S25Util } from "../util/s25-util";
import { DataAccess } from "../dataaccess/data.access";
import { Timeout } from "../decorators/timeout.decorator";
import { Invalidate } from "../decorators/cache.decorator";
import { Image } from "../pojo/Image";

export class ImageService {
    @Timeout
    public static getImages(type?: number | string) {
        let url = "/images.json";
        url = url + (type ? "?image_type=" + type : "");
        return DataAccess.get(DataAccess.injectCaller(url, "ImageDao.getImages")).then(function (data) {
            data = data && data.images && data.images.image && S25Util.array.forceArray(data.images.image);
            if (data && data.length) {
                data.forEach((image: Image.ImagesI) => {
                    image.image_desc = image.image_desc && image.image_desc.toString();
                    image.image_name = image.image_name && image.image_name.toString();
                });
                data.sort(S25Util.shallowSort("image_name"));
            }

            return data;
        });
    }

    @Timeout
    public static getImage(imageId: number | string) {
        return DataAccess.get(DataAccess.injectCaller("/image.json?image_id=" + imageId, "ImageService.getImage")).then(
            function (data) {
                return data && data.images && data.images.image;
            },
        );
    }

    @Timeout
    @Invalidate({ serviceName: "S25ImageService", methodName: "getUrl", patternFunc: (args: any) => args[0] })
    @Invalidate({
        serviceName: "S25ImageService",
        methodName: "getUrlBootstrapWidth",
        patternFunc: (args: any) => args[0],
    })
    @Invalidate({ serviceName: "S25ImageService", methodName: "getLoaded", patternFunc: (args: any) => args[0] })
    @Invalidate({
        serviceName: "S25ImageService",
        methodName: "getLoadedBootstrapWidth",
        patternFunc: (args: any) => args[0],
    })
    public static setImage(
        imageId: number,
        imageName: string,
        imageDesc: string,
        imageFileName: string,
        imageData: any,
        imageType?: number,
    ) {
        if (imageId) {
            return ImageService.getImage(imageId).then(function (data) {
                imageName = imageName ? imageName : data.image_name;
                imageDesc = imageDesc ? imageDesc : data.image_desc;
                imageType = imageType ? imageType : data.image_type;
                imageFileName = imageFileName ? imageFileName : data.file_name;

                //Sending an empty imageData node breaks the image.
                if (imageData && imageData.indexOf("http") > -1) {
                    imageData = data.image_data;
                } else {
                    imageData = S25Util.extractBase64FromURI(imageData);
                }

                return ImageService.putImage(imageId, imageName, imageDesc, imageFileName, imageData, imageType);
            });
        } else {
            return ImageService.postImage(
                imageName,
                imageDesc,
                imageFileName,
                S25Util.extractBase64FromURI(imageData),
                imageType,
            );
        }
    }

    @Timeout
    public static putImage(
        imageId: number,
        imageName: string,
        imageDesc: string,
        imageFileName: string,
        imageData: any,
        image_type?: number,
    ) {
        let payLoad = {
            images: {
                image: {
                    image_id: imageId,
                    image_name: imageName,
                    image_type: image_type || 1,
                    image_desc: imageDesc,
                    file_name: imageFileName,
                    image_data: imageData,
                },
            },
        };
        // //todo: fix image.json PUT and use that
        return DataAccess.put("/image.xml?image_id=" + imageId, S25Util.s25Json2Xml(payLoad)).then(function () {
            return {
                imageId: imageId,
            };
        });
    }

    @Timeout
    public static postImage(
        imageName: string,
        imageDesc: string,
        imageFileName: string,
        imageData: string,
        image_type?: number,
    ) {
        return DataAccess.post("/images.json").then(function (data) {
            let imageId = S25Util.propertyGetVal(data, "image_id");
            if (S25Util.isDefined(imageId)) {
                return ImageService.putImage(imageId, imageName, imageDesc, imageFileName, imageData, image_type).then(
                    function () {
                        return imageId;
                    },
                );
            }
        });
    }

    @Timeout
    public static deleteImage(imageId: number) {
        if (imageId) {
            return DataAccess.delete(
                DataAccess.injectCaller("/image.json?image_id=" + imageId, "ImageService.deleteImage"),
            ).then((resp) => {
                return resp;
            });
        } else {
            return S25Util.all({});
        }
    }
}
