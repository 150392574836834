import { formatCurrency, formatPercent } from "@angular/common";

export class FormatService {
    public static formatCurrency(
        value: number,
        locale: string,
        currency: string,
        currencyCode?: string,
        digitsInfo?: string,
    ): string {
        return formatCurrency(value, locale, currency, currencyCode, digitsInfo);
    }

    public static toDollars(value: number): string {
        return FormatService.formatCurrency(value, "en-US", "$");
    }

    // https://angular.io/api/common/formatPercent
    public static formatPercent(value: number, digitsInfo?: string): string {
        return formatPercent(value, "en-US", digitsInfo);
    }
}
