//@author: travis

import { DataAccess } from "../dataaccess/data.access";
import { Defer, jSith } from "../util/jquery-replacement";
import { S25Util } from "../util/s25-util";
import { ContactService } from "./contact.service";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { Timeout } from "../decorators/timeout.decorator";
import { NotificationService } from "./notification.service";
import { AuthService } from "./auth.service";

window.addEventListener(
    "message",
    function (event) {
        try {
            let obj = event && event.data && JSON.parse(event.data);
            if (obj.type === "oauth" && obj.data && authWindow) {
                let authCode = obj.data.split(/[?&]code=/i)[1];
                if (authDefer && authCode) {
                    authDefer.resolve(authCode);
                } else {
                    authDefer && authDefer.reject("Could not parse authentication token from freshbooks.");
                }
            }
        } catch (e) {}
    },
    false,
);

let authWindow: WindowProxy;
let authDefer: Defer;
let cachedBearerToken: {
    bearerToken: string;
    createdAt: number;
    expiresIn: number;
};

export type FBInstance = {
    instance_name: string;
    instance_id: number;
    useNewApi: boolean;
};

export class FreshbooksService {
    public static apiAuthUrl = "https://auth.freshbooks.com/service/auth/oauth/authorize";
    public static apiAuthBearerTokenUrl = "https://api.freshbooks.com/auth/oauth/token";
    public static apiBaseUrl = "https://api.freshbooks.com";
    public static clientId = "de49290aaf8308812a235360e72ec44e40a2ad84e640ef15a0308b44f0311933";
    public static clientSecret = "7ab73470a9f673bf0b1067d42b8220fd4eee0eac00207d62a70fa754386839a1";

    @Timeout
    public static getAuthenticationCode() {
        let promiseEntered = false;

        //if auth window NOT already open
        if (!authWindow || authWindow.closed) {
            authDefer = jSith.defer();
            authWindow = window.open(
                FreshbooksService.apiAuthUrl +
                    "?client_id=" +
                    FreshbooksService.clientId +
                    "&response_type=code&redirect_uri=" +
                    AuthService.getRedirectUrl(),
                "_blank",
            );

            if (!authWindow) {
                alert(
                    "Unable to create pop-up window for authentication. Please allow pop-ups in your browser and reload.",
                );
                authDefer.reject();
            } else {
                let closedTimer = window.setInterval(() => {
                    if (promiseEntered) {
                        closedTimer && window.clearInterval(closedTimer);
                    } else if (authWindow.closed) {
                        closedTimer && window.clearInterval(closedTimer);
                        setTimeout(() => {
                            if (!promiseEntered) {
                                //promise still isn't entered after timeout (avoiding race conditions)
                                alert(
                                    "Freshbooks authentication window has been closed. Please log into Freshbooks to use it in 25Live.",
                                );
                                authDefer.reject();
                            }
                        }, 1);
                    }
                }, 1000);
            }
        }

        return authDefer.promise.then(
            function (authCode: string) {
                promiseEntered = true;
                authWindow && authWindow.close && authWindow.close();
                authWindow = null;
                return authCode;
            },
            function (err: any) {
                promiseEntered = true;
                authWindow && authWindow.close && authWindow.close();
                authWindow = null;
                return jSith.reject(err);
            },
        );
    }

    @Timeout
    public static getAuthenticationBearerToken() {
        if (cachedBearerToken && Date.now() - cachedBearerToken.createdAt < cachedBearerToken.expiresIn) {
            return jSith.when(cachedBearerToken.bearerToken);
        }

        return FreshbooksService.getAuthenticationCode().then(function (authCode: string) {
            return DataAccess.post(FreshbooksService.apiAuthBearerTokenUrl, {
                grant_type: "authorization_code",
                client_id: FreshbooksService.clientId,
                code: authCode,
                client_secret: FreshbooksService.clientSecret,
                redirect_uri: AuthService.getRedirectUrl(),
            }).then(function (data) {
                if (data && data.access_token) {
                    cachedBearerToken = {
                        bearerToken: data.access_token,
                        createdAt: parseInt(data.created_at) * 1000,
                        expiresIn: parseInt(data.expires_in) * 1000,
                    };
                    return cachedBearerToken.bearerToken;
                } else {
                    return jSith.reject("Authentication failed. No access token found.");
                }
            });
        });
    }

    @Timeout
    public static freshbooksApiCall(instanceId?: string, subUrl?: string, method?: string, payload?: any) {
        return FreshbooksService.getAuthenticationBearerToken().then(function (bearerToken: any) {
            if (subUrl && !subUrl.startsWith("/")) {
                subUrl = "/" + subUrl;
            }
            let fullUrl = FreshbooksService.apiBaseUrl + subUrl;
            let accountPromise: any = (instanceId && FreshbooksService.getAccount(instanceId)) || jSith.when("");
            return accountPromise.then(function (accountId: any) {
                if (instanceId && !accountId && fullUrl.indexOf("/ACCOUNTID/") > -1) {
                    return jSith.reject("No Freshbooks account found.");
                }
                fullUrl = fullUrl.replace("/ACCOUNTID/", "/" + accountId + "/");
                if (!method || method === "get") {
                    return DataAccess.get(fullUrl, true, {
                        Authorization: "Bearer " + bearerToken,
                        "Api-Version": "alpha",
                    }).then(
                        function (data) {
                            return data;
                        },
                        function (err) {
                            return jSith.reject(err);
                        },
                    );
                } else if (method === "post") {
                    return DataAccess.post(fullUrl, payload, {
                        Authorization: "Bearer " + bearerToken,
                        "Api-Version": "alpha",
                    }).then(
                        function (data) {
                            return data;
                        },
                        function (err) {
                            return jSith.reject(err);
                        },
                    );
                } else if (method === "put") {
                    return DataAccess.put(fullUrl, payload, {
                        Authorization: "Bearer " + bearerToken,
                        "Api-Version": "alpha",
                    }).then(
                        function (data) {
                            console.log("data", data);
                            return data;
                        },
                        function (err) {
                            console.log("err", err);
                            return jSith.reject(err);
                        },
                    );
                } else if (method === "delete") {
                    return DataAccess.delete(fullUrl, payload, {
                        Authorization: "Bearer " + bearerToken,
                        "Api-Version": "alpha",
                    }).then(
                        function (data) {
                            return data;
                        },
                        function (err) {
                            return jSith.reject(err);
                        },
                    );
                }
            });
        });
    }

    @Timeout
    public static getFreshbooksTest() {
        return FreshbooksService.freshbooksApiCall("", "/test");
    }

    @Timeout
    public static getFreshbooksAccountIds() {
        return FreshbooksService.freshbooksApiCall("", "/auth/api/v1/users/me").then(function (data: any) {
            let resp = data && data.response;

            let businesses = ((resp && resp.business_memberships) || [])
                .map(function (bm: any) {
                    return (
                        bm.business &&
                        bm.business.account_id && {
                            name: bm.business.name || "Unknown",
                            accountId: bm.business.account_id,
                            type: "business",
                        }
                    );
                })
                .filter(S25Util.isTruthy);

            let roles = ((resp && resp.roles) || [])
                .map(function (r: any) {
                    return (
                        r.accountid &&
                        !S25Util.array.isIn(businesses, "accountId", r.accountid) && {
                            name: r.role,
                            accountId: r.accountid,
                            type: "role",
                        }
                    );
                })
                .filter(S25Util.isTruthy);

            return S25Util.array.uniqueByProp([].concat(businesses, roles), "accountId");
        });
    }

    @Timeout
    public static useNewApi(instanceId: string): Promise<boolean> {
        return DataAccess.get(
            DataAccess.injectCaller("/freshbooks/api/new.json?itemId=" + instanceId, "FreshbooksService.useNewApi"),
        ).then(
            function (data) {
                return S25Util.toBool(S25Util.propertyGet(data, "use_new_api"));
            },
            function () {
                return false;
            },
        );
    }

    @Timeout
    public static setUseNewApi(instanceId: string, useIt: boolean) {
        return DataAccess.put(
            DataAccess.injectCaller(
                "/freshbooks/api/new.json?itemId=" + instanceId + "&val=" + (useIt ? 1 : 0),
                "FreshbooksService.setUseNewApi",
            ),
        );
    }

    @Timeout
    @Invalidate({ serviceName: "FreshbooksService", methodName: "getInstances" })
    @Invalidate({ serviceName: "FreshbooksService", methodName: "hasFreshbooksApiToken" })
    public static addNewInstance(instanceId: string, name: string) {
        return DataAccess.put(
            DataAccess.injectCaller(
                "/freshbooks/api/new.json?itemId=" + instanceId + "&name=" + name,
                "FreshbooksService.setUseNewApi",
            ),
        );
    }

    @Timeout
    public static getAccount(instanceId: string) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/freshbooks/api/account.json?itemId=" + instanceId,
                "FreshbooksService.getAccount",
            ),
        ).then(
            function (data) {
                let accountId = S25Util.propertyGetVal(data, "account_id");
                if (accountId) {
                    return accountId;
                } else {
                    return FreshbooksService.getFreshbooksAccountIds().then(function (accountIds: any) {
                        let defer = jSith.defer();

                        if (accountIds.length === 1) {
                            defer.resolve(accountIds[0].accountId);
                        } else if (accountIds.length > 1) {
                            //favor business id if there's only 1
                            let businessAccountIds = accountIds.filter(function (account: any) {
                                return account.type === "business";
                            });

                            if (businessAccountIds.length === 1) {
                                defer.resolve(businessAccountIds[0].accountId);
                            } else {
                                let modalData: any = { accounts: accountIds };
                                //TODO: remove injection once s25ModalService is migrated
                                window.angBridge.$injector
                                    .get("s25ModalService")
                                    .closeCurrentModalAndOpenModal("freshbooks-account", modalData)
                                    .then(function () {
                                        if (modalData.chosenAccountId) {
                                            defer.resolve(modalData.chosenAccountId);
                                        } else {
                                            defer.resolve(null);
                                        }
                                    });
                            }
                        } else {
                            defer.resolve(null);
                        }

                        return defer.promise.then(function (accountId) {
                            if (accountId) {
                                FreshbooksService.setAccount(instanceId, accountId);
                                return accountId;
                            } else {
                                return "";
                            }
                        });
                    });
                }
            },
            function () {
                return "";
            },
        );
    }

    @Timeout
    public static setAccount(instanceId: string, accountId: string) {
        return DataAccess.put(
            DataAccess.injectCaller(
                "/freshbooks/api/account.json?itemId=" + instanceId + "&val=" + accountId,
                "FreshbooksService.setAccount",
            ),
        );
    }

    @Timeout
    @Cache({ immutable: true, targetName: "FreshbooksService" })
    public static hasFreshbooksApiToken() {
        return FreshbooksService.getInstances().then(function (data: any) {
            return !!S25Util.propertyGet(data, "instance_id");
        });
    }

    /*
    returns the freshbooks instances with the current instance
     */
    @Timeout
    @Cache({ immutable: true, targetName: "FreshbooksService" })
    public static getInstances() {
        return DataAccess.get(
            DataAccess.injectCaller("/freshbooks/api/instances.json", "FreshbooksService.getFreshbooksInstances"),
        );
    }

    @Timeout
    public static getInvoiceNewApi(invoiceId: string, instanceId: string) {
        return FreshbooksService.freshbooksApiCall(
            instanceId,
            "/accounting/account/ACCOUNTID/invoices/invoices/" + invoiceId,
        ).then(
            function (resp: any) {
                let invoice = resp && resp.response && resp.response.result && resp.response.result.invoice;
                if (invoice) {
                    return {
                        invoice_id: invoice.invoiceid,
                        accountid: invoice.accountid,
                        date: invoice.create_date,
                        links: {
                            view: "https://my.freshbooks.com/#/invoice/" + invoice.accountid + "-" + invoice.invoiceid,
                            edit: "https://my.freshbooks.com/#/invoice/" + invoice.accountid + "-" + invoice.invoiceid,
                        },
                        number: invoice.invoice_number,
                        status: invoice.payment_status,
                        paid: invoice.paid && invoice.paid.amount,
                        amount_outstanding: invoice.outstanding && invoice.outstanding.amount,
                    };
                }
                return null;
            },
            (err: any) => {
                console.log("catching an error", err);
                let errors = err?.response?.errors;
                if (errors && errors.length > 0) {
                    errors[0]?.message && NotificationService.post(errors[0]?.message);
                }
            },
        );
    }

    @Timeout
    public static getInvoice(invoiceId: string, instanceId: string) {
        return FreshbooksService.useNewApi(instanceId).then(function (useNewApi) {
            if (useNewApi) {
                return FreshbooksService.getInvoiceNewApi(invoiceId, instanceId);
            } else {
                let payload = S25Util.xml.json2xml_str({
                    request: {
                        _method: "invoice.get",
                        invoice_id: invoiceId,
                    },
                });
                return DataAccess.post(
                    DataAccess.injectCaller(
                        "/freshbooks/api/proxy.xml?instanceId=" + instanceId,
                        "FreshbooksService.getInvoice",
                    ),
                    payload,
                ).then(function (data) {
                    return (data && S25Util.propertyGet(S25Util.prettyConv(data, null, null), "invoice")) || null;
                });
            }
        });
    }

    @Timeout
    public static getClientNewApi(contactEmail: string, instanceId: string, page?: number, clientList?: any[]): any {
        page = page || 1;
        clientList = clientList || [];
        return FreshbooksService.freshbooksApiCall(
            instanceId,
            "/accounting/account/ACCOUNTID/users/clients?search[email]=" + contactEmail + "&per_page=100&page=" + page,
        ).then(function (resp: any) {
            let clients = S25Util.array.forceArray(resp?.response?.result?.clients);
            let pages = parseInt(resp?.response?.result?.pages || 1);
            if (clients) {
                clients = clients.map(function (client: any) {
                    return {
                        client_id: client.id,
                        accountid: client.accounting_systemid,
                        organization: client.organization,
                    };
                });
            }

            clientList = clientList.concat(clients);

            if (pages && page < pages) {
                return FreshbooksService.getClientNewApi(contactEmail, instanceId, page + 1, clientList);
            } else {
                return clientList;
            }
        });
    }

    @Timeout
    public static getClient(contactEmail: string, instanceId: string) {
        return FreshbooksService.useNewApi(instanceId).then(function (useNewApi) {
            if (useNewApi) {
                return FreshbooksService.getClientNewApi(contactEmail, instanceId);
            } else {
                return FreshbooksService.getClientPaginated(contactEmail, instanceId);
            }
        });
    }

    public static getClientPaginated(contactEmail: string, instanceId: string, page?: number, clientList?: any[]): any {
        page = page || 1;
        clientList = clientList || [];
        let payload = S25Util.xml.json2xml_str({
            request: {
                _method: "client.list",
                email: contactEmail,
                per_page: 100,
                page: page,
            },
        });
        return DataAccess.post(
            DataAccess.injectCaller(
                "/freshbooks/api/proxy.xml?instanceId=" + instanceId,
                "FreshbooksService.getClient",
            ),
            payload,
        ).then(function (data) {
            let massagedData = data && S25Util.prettyConv(data, null, { client: true });
            let pages = parseInt(S25Util.propertyGet(massagedData, "pages") || 1);
            let clients = S25Util.propertyGet(massagedData, "client");
            if (clients && clients.length > 0) {
                //save list of clients for this page
                clientList = clientList.concat(clients);
            }
            if (pages > page) {
                //there are more pages
                return FreshbooksService.getClientPaginated(contactEmail, instanceId, page + 1, clientList); //accumulate client list
            } else {
                //no more pages, return client list
                return clientList;
            }
        });
    }

    //Keeping Data call seperate to mock for testing
    public static getClientDao(instanceId: string, payload?: any[]): any {
        return DataAccess.post(
            DataAccess.injectCaller(
                "/freshbooks/api/proxy.xml?instanceId=" + instanceId,
                "FreshbooksService.getClient",
            ),
            payload,
        );
    }

    @Timeout
    public static getClientByOrg(contactEmail: string, instanceId: string, orgName: string) {
        return FreshbooksService.getClient(contactEmail, instanceId).then(function (clients) {
            for (let i = 0; i < clients.length; i++) {
                if (clients[i].organization && clients[i].organization.toLowerCase() === orgName.toLowerCase()) {
                    return clients[i].client_id;
                }
            }
        });
    }

    @Timeout
    public static createClientNewApi(client: any, instanceId: string): any {
        let clientNewApi = {
            client: {
                email: client.email,
                fname: client.first_name,
                lname: client.last_name,
                username: client.username,
                bus_phone: client.work_phone,
                home_phone: client.home_phone,
                p_street: client.p_street,
                p_city: client.p_city,
                p_province: client.p_state,
                p_country: client.p_country,
                p_code: client.p_code,
                organization: client.organization,
            },
        };
        return FreshbooksService.freshbooksApiCall(
            instanceId,
            "/accounting/account/ACCOUNTID/users/clients",
            "post",
            clientNewApi,
        ).then(function (resp: any) {
            let client = resp && resp.response && resp.response.result && resp.response.result.client;
            return client && { client_id: client.id };
        });
    }

    @Timeout
    public static createClient(contactId: number, orgName: string, instanceId: string) {
        return FreshbooksService.formClientFromContact(contactId).then(function (client) {
            if (client && client.client) {
                client.client.organization = orgName;
                return FreshbooksService.useNewApi(instanceId).then(function (useNewApi) {
                    if (useNewApi) {
                        return FreshbooksService.createClientNewApi(client.client, instanceId);
                    } else {
                        let payload = S25Util.xml.json2xml_str({
                            request: {
                                _method: "client.create",
                                client: client.client,
                            },
                        });
                        return DataAccess.post(
                            DataAccess.injectCaller(
                                "/freshbooks/api/proxy.xml?instanceId=" + instanceId,
                                "FreshbooksService.createClient",
                            ),
                            payload,
                        ).then(function (data) {
                            return data && S25Util.prettyConv(data, null, null);
                        });
                    }
                });
            }
        });
    }

    @Timeout
    public static createOrOverwriteInvoiceNewApi(invoiceModel: any, instanceId: string, mode: string): any {
        let method = mode === "create" ? "post" : "put";
        let invoiceNewApi: any = {
            invoice: {
                customerid: invoiceModel.client_id,
                create_date: S25Util.date.toS25ISODateStr(new Date()),
                notes: invoiceModel.notes,
                terms: invoiceModel.terms,
                lines:
                    (invoiceModel.lines &&
                        invoiceModel.lines.line &&
                        invoiceModel.lines.line.map &&
                        invoiceModel.lines.line.map(function (line: any) {
                            let type: any = { item: 0 };
                            let lineNewApi: any = {
                                type: type[line.type],
                                name: line.name,
                                description: line.description,
                            };

                            if (S25Util.isDefined(line.quantity)) {
                                lineNewApi.qty = line.quantity;
                            }

                            if (S25Util.isDefined(line.unit_cost)) {
                                lineNewApi.unit_cost = {
                                    amount: line.unit_cost,
                                    code: "USD",
                                };
                            }

                            return lineNewApi;
                        })) ||
                    [],
            },
        };
        let subUrl = "/accounting/account/ACCOUNTID/invoices/invoices";
        if (method === "put") {
            subUrl += "/" + invoiceModel.invoice_id;
        }
        return FreshbooksService.freshbooksApiCall(instanceId, subUrl, method, invoiceNewApi).then(
            function (resp: any) {
                let invoice = resp?.response?.result?.invoice;
                return invoice && { invoice_id: invoice.id };
            },
            function (err) {
                console.log("invoice error", err);
                let errors = err?.error?.response?.errors;
                if (errors && errors.length > 0) {
                    errors[0]?.message && NotificationService.post(errors[0]?.message);
                }
            },
        );
    }

    @Timeout
    public static createOrOverwriteInvoice(
        invoicesByEvent: any,
        clientId: number,
        notes: string,
        terms: any,
        instanceId: string,
        invoiceIdToOverwrite: any,
    ) {
        let invoiceModel = FreshbooksService.formFreshbooksInvoice(invoicesByEvent, clientId, notes, terms);
        let mode = "create";
        if (invoiceIdToOverwrite) {
            invoiceModel.invoice.invoice_id = invoiceIdToOverwrite; //invoiceModel must have invoice_id in it...
            mode = "update";
        }
        return FreshbooksService.useNewApi(instanceId).then(function (useNewApi) {
            if (useNewApi) {
                return FreshbooksService.createOrOverwriteInvoiceNewApi(invoiceModel.invoice, instanceId, mode);
            } else {
                var payload = S25Util.xml.json2xml_str({
                    request: {
                        _method: "invoice." + mode,
                        invoice: invoiceModel.invoice,
                    },
                });
                return DataAccess.post(
                    DataAccess.injectCaller(
                        "/freshbooks/api/proxy.xml?instanceId=" + instanceId,
                        "FreshbooksService.writeToInvoice",
                    ),
                    payload,
                ).then(function (data) {
                    return data && S25Util.prettyConv(data, null, null);
                });
            }
        });
    }

    @Timeout
    public static getS25Invoices(eventId: number, evBillId: number) {
        let url = "/invoice/get.json";

        if (evBillId) {
            url += "?bill_id=" + evBillId;
        } else {
            url += "?event_id=" + eventId;
        }

        return DataAccess.get(DataAccess.injectCaller(url, "FreshbooksService.getS25Invoices"));
    }

    @Timeout
    public static getInvoiceLock(eventId: number, evBillId: number) {
        let url = "/invoice/lock.json";

        if (evBillId) {
            url += "?bill_id=" + evBillId;
        } else {
            url += "?event_id=" + eventId;
        }

        return DataAccess.put(DataAccess.injectCaller(url, "FreshbooksService.getInvoiceLock"));
    }

    @Timeout
    public static deleteInvoiceLock(invoiceLockIds: number[]) {
        return DataAccess.put(
            DataAccess.injectCaller("/invoice/delete_lock.json", "FreshbooksService.deleteInvoiceLock"),
            { root: { invoice_lock_id: invoiceLockIds } },
        );
    }

    @Timeout
    public static setS25InvoiceOnEvent(eventId: number, orgId: number, invoiceId: number, freshbooksInstId: string) {
        return FreshbooksService.setS25InvoiceDao(eventId, null, null, orgId, invoiceId, freshbooksInstId);
    }

    @Timeout
    public static setS25InvoiceOnSet(setId: number, orgId: number, invoiceId: number, freshbooksInstId: string) {
        return FreshbooksService.setS25InvoiceDao(null, setId, null, orgId, invoiceId, freshbooksInstId);
    }

    @Timeout
    public static setS25InvoiceOnBill(billId: number, orgId: number, invoiceId: number, freshbooksInstId: string) {
        return FreshbooksService.setS25InvoiceDao(null, null, billId, orgId, invoiceId, freshbooksInstId);
    }

    @Timeout
    public static setS25InvoiceDao(
        eventId: number,
        setId: number,
        billId: number,
        orgId: number,
        invoiceId: number,
        freshbooksInstId: string,
    ) {
        /*
            eventId -> event_id
            setId -> obj_id
            orgId -> organization_id
            invoiceId -> itemId
            freshbooksInstId -> parent_id
         */
        return DataAccess.post(
            DataAccess.injectCaller(
                "/invoice/set.json?itemId=" +
                    invoiceId +
                    "&organization_id=" +
                    orgId +
                    "&parent_id=" +
                    freshbooksInstId +
                    "&" +
                    (eventId ? "event_id=" + eventId : setId ? "obj_id=" + setId : "bill_id=" + billId),
                "FreshbooksService.setS25Invoice",
            ),
        );
    }

    @Timeout
    public static formClientFromContact(contactId: number) {
        var client: any = { client: {} };
        return ContactService.getContact(contactId, "extended", ["address"]).then(function (data) {
            if (data && data.contacts && data.contacts.contact) {
                var contact = data.contacts.contact;
                var workAddr = S25Util.propertyGetParentWithChildValue(data, "address_type", 3);
                var homeAddr = S25Util.propertyGetParentWithChildValue(data, "address_type", 4);
                client.client.first_name = contact.first_name;
                client.client.last_name = contact.last_name;
                client.client.email = S25Util.propertyGetVal(workAddr, "email");
                client.client.username = contact.r25user && contact.r25user.username;
                client.client.work_phone = S25Util.propertyGetVal(workAddr, "phone");
                client.client.home_phone = S25Util.propertyGetVal(homeAddr, "phone");
                client.client.p_street1 = S25Util.propertyGetVal(workAddr, "street_address");
                client.client.p_city = S25Util.propertyGetVal(workAddr, "city");
                client.client.p_state = S25Util.propertyGetVal(workAddr, "state_prov");
                client.client.p_country = S25Util.propertyGetVal(workAddr, "country");
                client.client.p_code = S25Util.propertyGetVal(workAddr, "zip_post");
                return client;
            }
        });
    }

    /*
        @invoicesByEvent: {
        events: [
            eventName, eventLocator, eventId, eventStartDT,
            profile: [
                profileName,
                billingItems: [{...}]
            ]
        ],
        totalOrgCharge
    }
    */
    public static formFreshbooksInvoice(invoicesByEvent: any, clientId: number, notes: string, terms?: number) {
        let invoice: any = {
            invoice: {
                client_id: clientId,
                notes: notes,
                terms: terms,
                lines: { line: [] },
            },
        };
        jSith.forEach(invoicesByEvent.events, function (_, event) {
            //always create event line (upgraded from only creating it when there are multiple events, see: ANG-1456)
            if (invoicesByEvent.events.length >= 1) {
                invoice.invoice.lines.line.push({
                    name: "Event",
                    unit_cost: 0,
                    quantity: 1,
                    type: "Item",
                    description: event.eventName || "",
                });
            }

            jSith.forEach(event.profile, function (_, profile) {
                //if more than 1 profile, create profile line
                if (event.profile.length > 1) {
                    invoice.invoice.lines.line.push({
                        name: "Group",
                        unit_cost: 0,
                        quantity: 1,
                        type: "Item",
                        description: profile.profileName || "",
                    });
                }

                //line items
                jSith.forEach(profile.billingItems, function (_, billItem) {
                    var profileId = parseInt(billItem.ev_dt_profile_id);
                    var totalCharge = parseFloat(billItem.total_charge);
                    var billItemTypeId = parseInt(billItem.bill_item_type_id);
                    if (
                        profileId > 0 &&
                        billItemTypeId > 0 &&
                        !isNaN(totalCharge) &&
                        totalCharge !== 0 &&
                        !isNaN(parseFloat(billItem.taxable_amt)) &&
                        parseFloat(billItem.taxable_amt) !== 0
                    ) {
                        invoice.invoice.lines.line.push({
                            name: billItem.bill_item_name || "",
                            unit_cost: billItem.taxable_amt,
                            quantity: 1,
                            type: "Item",
                            description:
                                billItemTypeId === 4
                                    ? (billItem.rate_description ? billItem.rate_description + ", " : "") +
                                      "Quantity: " +
                                      billItem.total_count
                                    : "",
                        });
                        if (billItem.tax) {
                            jSith.forEach(billItem.tax, function (_, tax) {
                                invoice.invoice.lines.line.push({
                                    name: tax.tax_name,
                                    unit_cost: tax.tax_charge,
                                    quantity: 1,
                                    type: "Item",
                                });
                            });
                        }
                    }
                });
            });

            //requirements
            jSith.forEach(event.requirements, function (_, billItem) {
                var billItemTypeId = parseInt(billItem.bill_item_type_id);
                invoice.invoice.lines.line.push({
                    name: billItem.bill_item_name,
                    unit_cost: billItem.list_price,
                    quantity: 1,
                    type: "Item",
                    description: billItemTypeId === 4 ? billItem.rate_description || "" : "",
                });
            });

            //adjustments at event/org level
            jSith.forEach(event.orgAdjustments, function (_, billItem) {
                invoice.invoice.lines.line.push({
                    name: billItem.bill_item_type_name, //note: this is bill item TYPE name here
                    unit_cost: billItem.total_charge,
                    quantity: 1,
                    type: "Item",
                    description: billItem.adjustment_name || "",
                });
            });
        });
        return invoice;
    }
}
